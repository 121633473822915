<template>
	<div>
		<a-row>
			<a-col :span="24">
				<span id="refresh" @click="refresh"><a-icon type="reload" :class="showTurn?'turn':''" />点击刷新</span>
			</a-col>
		</a-row>
		<a-row :gutter="24" v-if="type==1">
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="待处理业务" :total="(analysisData&&analysisData.waitTotal)| NumberFormat" class="cardBlock"></chart-card>
			</a-col>
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="累计处理业务" :total="(analysisData&&analysisData.completeTotal) | NumberFormat" class="cardBlock"></chart-card>
			</a-col>
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="昨日处理业务" :total="(analysisData&&analysisData.yesterdayCompleteNum) | NumberFormat" class="cardBlock"></chart-card>
			</a-col>
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="本月处理业务" :total="(analysisData&&analysisData.monthCompleteNum) | NumberFormat" class="cardBlock"></chart-card>
			</a-col>
		</a-row>
		
		<a-row :gutter="24" v-else-if="type==2">
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="累计客户" :total="(analysisData&&analysisData.wxMember)| NumberFormat" class="cardBlock"></chart-card>
			</a-col>
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="累计办理业务" :total="(analysisData&&analysisData.completeTotal)  | NumberFormat" class="cardBlock"></chart-card>
			</a-col>
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="累计推送停水通知" :total="(analysisData&&analysisData.tsCount) | NumberFormat" class="cardBlock"></chart-card>
			</a-col>
			<a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
				<chart-card :loading="loading" title="累计粉丝数" :total="6500 | NumberFormat" class="cardBlock"></chart-card>
			</a-col>
		</a-row>
		
		<a-card :loading="loading" class="card" v-if="type==1">
			<a-row class="container">  
				<a-col :span="24" class="title">待处理业务</a-col>
				<a-col :sm="24" :md="12" :xl="6" v-for="(item,index) in businessList" :key="index">
					<div class="bList" @click="redirect(item.url)">
						<div class="sname">{{item.name}}</div>
						<div class="unresolve">
							<span>{{item.waitNum}}</span>
							<span>待处理</span>
						</div>
					</div>
				</a-col>
			</a-row>
		</a-card>
		<a-card :loading="loading" style="margin-top:24px;" class="card" v-else-if="type==2">
			<a-row class="container container_chart">
				<a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="14">
					<div class="charts_title">新增客户走势</div>
					<div>
						<bar :data="barData" title="" />
					</div>
				</a-col>
				<a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="10">
					<div class="charts_title">业务办理数据</div>
					<div>
						<rank-list title="" :list="sortCompleteBusinessList"/>
					</div>
				</a-col>
			</a-row>
		</a-card>
	</div>
</template>

<script>
	import moment from 'moment'
	import {
		ChartCard,
		MiniArea,
		MiniBar,
		MiniProgress,
		RankList,
		Bar,
		Trend,
		NumberInfo,
		MiniSmoothArea
	} from '@/components'
	import {
		baseMixin
	} from '@/store/app-mixin'
	import { analysis } from '@/api/system'
	const barData = []
	
	export default {
		name: 'Analysis',
		mixins: [baseMixin],
		components: {
			ChartCard,
			MiniArea,
			MiniBar,
			MiniProgress,
			RankList,
			Bar,
			Trend,
			NumberInfo,
			MiniSmoothArea
		},
		data() {
			return {
				loading: true,
				barData,
				type:0,   //1 办理业务人员  2 运营人员
				analysisData:'',   //统计数据
				businessList:[],
				sortCompleteBusinessList:[],
				fresh:false,
				showTurn:false,
			}
		},
		created() {
			this.getData()
		},
		methods:{
			redirect(url){
				this.$router.push({path:url})
			},
			refresh(){
				this.fresh = true
				this.showTurn = true
				this.getData()
			},
			getData(){
				let params = {
					fresh:this.fresh
				}
				analysis(params).then(res =>{
					if(res.code==200){
						let data = res.data;
						this.analysisData = data;
						this.businessList = data.businessList;
						this.sortCompleteBusinessList = data.sortCompleteBusinessList;
						let cData = [];
						for(let i in data.userAnalysis.userNum){
							//console.log('i',i)
							cData.push({
								y: `${data.userAnalysis.userNum[i]}`,
								x: data.userAnalysis.time[i]
							})
						}
						this.barData = cData
						if(data.businessList.length==0){
							this.type=2
						}else{
							this.type=1
						}
						this.loading = false
						this.fresh = false
						setTimeout(_=>{
							this.showTurn = false
						},500)
					}
				}).catch(res=>{
					
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.extra-wrapper {
		line-height: 55px;
		padding-right: 24px;

		.extra-item {
			display: inline-block;
			margin-right: 24px;

			a {
				margin-left: 24px;
			}
		}
	}

	.antd-pro-pages-dashboard-analysis-twoColLayout {
		position: relative;
		display: flex;
		display: block;
		flex-flow: row wrap;
	}

	.antd-pro-pages-dashboard-analysis-salesCard {
		height: calc(100% - 24px);

		/deep/ .ant-card-head {
			position: relative;
		}
	}

	.dashboard-analysis-iconGroup {
		i {
			margin-left: 16px;
			color: rgba(0, 0, 0, .45);
			cursor: pointer;
			transition: color .32s;
			color: black;
		}
	}

	.analysis-salesTypeRadio {
		position: absolute;
		right: 54px;
		bottom: 12px;
	}
</style>
<style>
	.cardBlock .chart-card-content{
		display: none;
	}
	.cardBlock .chart-card-footer{
		display: none;
	}
	.container{
		background: #fff;
		padding-bottom: 20px;
	}
	.title{
		height: 54px;
		padding: 0 24px;
		display: flex;
		align-items: center;
		font-size:16px;
		font-family:PingFangSC-Medium,PingFang SC;
		font-weight:500;
		color:rgba(0,0,0,0.85);
	}
	.bList{
		padding:20px 24px;
		border-right:1px solid #E9E9E9;
		border-top:1px solid #E9E9E9;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}
	.sname{
		height:22px;
		font-size:14px;
		font-family:PingFangSC-Medium,PingFang SC;
		font-weight:500;
		color:rgba(0,0,0,1);
		line-height:22px;
	}
	.unresolve{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	.unresolve span:nth-child(1){
		height:33px;
		font-size:24px;
		font-family:PingFangSC-Regular,PingFang SC;
		font-weight:400;
		color:rgba(224,32,32,1);
		line-height:33px;
	}
	.unresolve span:nth-child(2){
		height:22px;
		font-size:12px;
		font-family:PingFangSC-Regular,PingFang SC;
		font-weight:400;
		color:rgba(102,102,102,1);
		line-height:22px;
	}
	.charts_title{
		height:22px;
		font-size:16px;
		font-family:PingFangSC-Medium,PingFang SC;
		font-weight:500;
		color:rgba(0,0,0,0.85);
		line-height:22px;
		padding-left: 24px;
	}
	.container_chart{
		margin-top:20px;
		padding-top:18px;
	}
	.rank .title{
		display: none;
	}
	.card{
		border:none;
	}
	.card .ant-card-body{
		padding:0;
	}
	#refresh{
		display: flex;
		align-items: center;
		float:right;
		margin-bottom: 10px;
		cursor: pointer;
	}
	#refresh i{
		margin-right: 5px;
	}
	.turn{
		animation:turn 1s linear infinite; 
	}
	@keyframes turn{
		0%{-webkit-transform:rotate(0deg);}
		25%{-webkit-transform:rotate(90deg);}
		50%{-webkit-transform:rotate(180deg);}
		75%{-webkit-transform:rotate(270deg);}
		100%{-webkit-transform:rotate(360deg);}
	}
</style>
